<template>
  <div class="animated fadeIn">
    <b-card>
      <div class="card-body">
        <form>
          <h5>
            Chain details
            <b-spinner v-if="isLoading" small type="grow" class="mb-1" />
          </h5>

          <hr />

          <b-row>
            <b-col lg="3" md="2" sm="2">
              <inline-input
                :id="controls.name.id"
                :value="data.name"
                :label="controls.name.label"
                :readonly="false"
                :mode="mode"
                :required="true"
                @changed="updateField"
              />
            </b-col>
            <b-col lg="3" md="2" sm="2">
              <inline-input
                :id="controls.domain.id"
                :value="data.domain"
                :label="controls.domain.label"
                :readonly="false"
                :mode="mode"
                :required="false"
                @changed="updateField"
              />
            </b-col>
            <b-col lg="3" md="2" sm="2">
              <inline-input
                :id="controls.keyword.id"
                :value="data.keyword"
                :label="controls.keyword.label"
                :readonly="false"
                :mode="mode"
                :required="false"
                @changed="updateField"
              />
            </b-col>
            <b-col>
              <inline-switch
                :id="controls.approved.id"
                :value="data.approved"
                :readonly="controls.approved.readonly"
                :label="controls.approved.label"
                @changed="updateField"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="12" md="2" sm="2">
              <inline-input
                :id="controls.description.id"
                :value="data.description"
                :label="controls.description.label"
                :is-text-area="true"
                :readonly="false"
                :mode="mode"
                :required="false"
                @changed="updateField"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="2" md="2" sm="2">
              <inline-input
                :id="controls.created.id"
                :value="data.created"
                :label="controls.created.label"
                :readonly="true"
                :mode="mode"
                :required="false"
                @changed="updateField"
              />
            </b-col>
            <b-col lg="2" md="2" sm="2">
              <inline-input
                :id="controls.modified.id"
                :value="data.modified"
                :label="controls.modified.label"
                :readonly="true"
                :mode="mode"
                :required="false"
                @changed="updateField"
              />
            </b-col>
          </b-row>
          <div v-if="id">
            <hr />
            <h5>
              Chain accounts
            </h5>
            <hr />
            <b-row>
              <b-col lg="4">
                <b-button-group>
                  <button
                    class="btn btn-outline-dark btn"
                    type="button"
                    @click="showModalAccountsList()"
                    title="Include accounts"
                  >
                    <font-awesome-icon icon="plus" />
                  </button>
                  <button
                    class="btn btn-outline-dark btn"
                    type="button"
                    @click="excludeAccounts()"
                    :disabled="chainAccounts.selected.length === 0"
                    title="Exclude accounts"
                  >
                    <font-awesome-icon icon="minus" />
                  </button>
                </b-button-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <accounts-table
                  ref="chain-accounts"
                  :per-page="5"
                  :show-add-button="false"
                  @loaded="onAccountsTableLoad"
                  :selectable="true"
                  @row-select="onChainAccountSelected"
                />
              </b-col>
            </b-row>
          </div>
        </form>

        <hr />

        <form-submission-actions
          :mode="mode"
          :loading="{
            save: saveInProgress,
            saveAndView: saveAndViewInProgress
          }"
          :buttons-visibility="{
            previous: $customTable.getPrevKey($route.meta.module, id),
            next: $customTable.getNextKey($route.meta.module, id)
          }"
          @previous-item="
            $router.push({
              name: $route.name,
              params: {
                action: $route.params.action,
                id: $customTable.getPrevKey($route.meta.module, id)
              }
            })
          "
          @next-item="
            $router.push({
              name: $route.name,
              params: {
                action: $route.params.action,
                id: $customTable.getNextKey($route.meta.module, id)
              }
            })
          "
          @save="
            save('tabular').then(response =>
              response ? $router.push({ name: 'Chains manager' }) : false
            )
          "
          @save-and-view="
            save('view').then(response =>
              response
                ? $router.push({
                    name: 'Chain submission',
                    params: { action: 'view', id: response }
                  })
                : false
            )
          "
          @edit="
            $router.push({
              name: 'Chain submission',
              params: { action: 'edit', id: id }
            })
          "
          @back="$router.push($store.getters['router/previousRoute'])"
        />
      </div>
    </b-card>
    <b-modal
      ref="modal-accounts-list"
      centered
      hide-footer
      size="xl"
      title="Select accounts"
    >
      <accounts-table
        ref="modal-accounts"
        :autoload="true"
        :per-page="5"
        :show-add-button="false"
        :show-actions="false"
        @loaded="onAccountsTableLoad"
        :selectable="true"
        @row-select="onModalAccountSelected"
      />

      <hr />

      <div class="form-row d-flex  justify-content-end">
        <b-button
          variant="outline-dark"
          class="m-1"
          :disabled="modalAccountsList.selected.length === 0"
          @click="includeAccounts()"
        >
          <font-awesome-icon icon="plus" /> Add
        </b-button>
        <b-button
          variant="outline-dark"
          class="m-1"
          @click="closeModalAccountsList()"
        >
          <font-awesome-icon icon="times" /> Close
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Vue from "vue";

import FormSubmissionActions from "@/components/FormSubmissionActions";
import AccountsTable from "@/views/RelationshipManagement/AccountsTable";

import InlineInput from "@/components/InlineInput";

import InlineSwitch from "@/components/InlineSwitch";

import { mapState, mapActions } from "vuex";

export default {
  name: "ChainSubmissionForm",
  props: {
    id: { type: String, default: "" },
    action: { type: String, default: "" }
  },

  components: {
    AccountsTable,
    FormSubmissionActions,
    InlineInput,

    InlineSwitch
  },
  data: function() {
    return {
      baseApiUrl: "chains",
      isLoading: false,
      saveInProgress: false,
      saveAndViewInProgress: false,
      controls: {
        buttons: {
          edit: {
            id: "button:details_edit"
          },
          save: {
            id: "button:details_save"
          }
        },
        name: {
          id: "input:name",
          label: "Name",
          changed: false,
          readonly: false
        },
        domain: {
          id: "input:domain",
          label: "Domain",
          changed: false,
          readonly: false
        },
        keyword: {
          id: "input:keyword",
          label: "Keyword",
          changed: false,
          readonly: false
        },

        approved: {
          id: "switch:approved",
          label: "Approved",
          changed: false,
          readonly: false
        },

        created: {
          id: "input:created",
          label: "Created",
          changed: false,
          readonly: false
        },
        modified: {
          id: "input:modified",
          label: "Modified",
          changed: false,
          readonly: false
        },
        description: {
          id: "input:description",
          label: "Description",
          changed: false,
          readonly: false
        }
      },
      data: {
        id: "",
        name: "",
        domain: "",
        keyword: "",
        approved: false,
        created: "",
        modified: "",
        description: "",
        accounts: []
      },
      dataTable: {
        isLoading: false,
        options: {
          uniqueKey: "ID",
          filterByColumn: false,
          showChildRowToggler: false,
          filterable: [],
          editableColumns: [],
          perPage: 10,
          perPageValues: [],
          saveState: true,
          storage: "local"
        },
        columns: ["Account ID", "Account Name", "Actions"],
        dataSet: [],
        totalRecords: 0,
        isInserting: false,
        dropdowns: [
          {
            name: "Account Name",
            options: [],
            value: [],
            multiple: false,
            allowEmpty: false
          }
        ]
      },
      districts: [],
      chainAccounts: {
        selected: []
      },
      modalAccountsList: {
        selected: []
      }
    };
  },
  computed: mapState({
    profile: state => state.profile
  }),
  created() {
    this.initialize();
  },
  mounted() {},
  methods: {
    ...mapActions("profile", ["fetchProfile"]),

    async initialize() {
      if (this.action === "create") {
        this.mode = this.$constants.FORM_MODE.CREATE;
      }
      if (this.action === "edit") {
        this.mode = this.$constants.FORM_MODE.EDIT;
      }
      if (this.action === "view") {
        this.mode = this.$constants.FORM_MODE.VIEW;
      }

      this.data.id = this.id;

      if (this.mode !== this.$constants.FORM_MODE.CREATE && this.id) {
        this.fetchData(this.id);
      }
    },
    edit() {},

    save(_mode) {
      if (!this.$form.testForm(this)) {
        this.$form.makeToastError("Form contains errors");
        return Promise.resolve(false);
      }

      this.saveInProgress = _mode === "tabular";
      this.saveAndViewInProgress = _mode === "view";

      let method = "";

      let url = this.baseApiUrl;

      if (this.mode === this.$constants.FORM_MODE.CREATE) {
        method = "put";
        url = this.baseApiUrl;
      }
      if (this.mode === this.$constants.FORM_MODE.EDIT) {
        method = "put";
        url = `${this.baseApiUrl}/${this.data.id}`;
      }

      this.isLoading = true;

      return this.$api[method](url, this.data)
        .then(response => {
          this.isLoading = false;
          this.saveInProgress = false;
          this.saveAndViewInProgress = false;

          this.$form.makeToastInfo(response.message);

          this.$router.currentRoute.params.id = response.id;

          return response.id;
        })
        .catch(error => {
          this.isLoading = false;
          this.saveInProgress = false;
          this.saveAndViewInProgress = false;

          this.$form.makeToastError(error.message);
          return Promise.resolve(false);
        });
    },
    updateField(field, value, mode) {
      this.$form.updateField(this.baseApiUrl, this, field, value, mode);
    },
    async fetchData(id) {
      let self = this;

      this.isLoading = true;

      return this.$api
        .get(`${this.baseApiUrl}/${id}`)
        .then(response => {
          this.isLoading = false;

          if (self.$_.isEmpty(response)) return;

          self.data.id = self.id;

          self.data.name = response["Name"];
          self.data.domain = response["Domain"];
          self.data.keyword = response["Keyword"];
          self.data.approved = response["Approved"] === "Yes" ? true : false;
          self.data.created = response["Created"];
          self.data.modified = response["Modified"];
          self.data.description = response["Description"];

          self.drawDataTable();
        })
        .catch(error => {
          this.isLoading = false;

          console.log(error);
        });
    },
    async drawDataTable() {
      let filter = {
        chain: [this.id]
      };

      await this.$refs["chain-accounts"].getData(filter);
    },
    deleteItem: async function(id) {
      let row = this.dataTable.dataSet.find(item => item.ID === id);

      let confirm = await this.$form.showConfirmation(
        `Account '${row["Account Name"]}' will be removed from this chain. Do you want to proceed?`
      );

      if (!confirm) return;

      let self = this;

      this.dataTable.dataSet = this.dataTable.dataSet.filter(i => i.ID !== id);

      if (this.mode === this.$constants.FORM_MODE.VIEW) {
        this.$api
          .delete(`chains/${id}/accounts`, {
            chain_id: this.id,
            account_id: row.ID
          })
          .then(() => {
            this.$form.makeToastInfo("Account removed");

            self.drawDataTable();
          })
          .catch(response => {
            console.log(response);

            this.$form.makeToastError(response.data.message);

            self.drawDataTable();
          });
      }
    },
    onColumnUpdate(id, column, value) {
      let idx = this.dataTable.dataSet.findIndex(item => item["ID"] === id);

      let item = this.dataTable.dataSet[idx];

      item[column] = value;

      Vue.set(this.dataTable.dataSet, idx, item);
    },
    showModalAccountsList() {
      this.$refs["modal-accounts-list"].show();
    },
    closeModalAccountsList() {
      this.$refs["modal-accounts-list"].hide();
    },
    async includeAccounts() {
      let self = this;
      this.$refs["modal-accounts-list"].hide();

      //deselect checkboxes
      this.modalAccountsList.selected.forEach(item => (item["#"] = false));

      this.$refs["chain-accounts"].dataTable.dataSet = [
        ...this.modalAccountsList.selected
      ];

      let data = this.modalAccountsList.selected.map(t => ({
        chain_id: this.id,
        account_id: t.ID
      }));

      self.modalAccountsList.selected = [];

      this.$api
        .put(`chains/${this.id}/accounts/include`, data)
        .then(() => {
          self.fetchData(self.id);

          self.drawDataTable();
        })
        .catch(response => {
          console.log(response);

          self.$form.makeToastError(`Error: ${response.message}`);

          self.fetchData(self.id);

          self.drawDataTable();
        });
    },
    excludeAccounts: async function() {
      let confirm = await this.$form.showConfirmation(
        "Selected accounts will be excluded from this chain. Do you want to proceed?"
      );

      if (!confirm) return;

      let self = this;
      let data = this.chainAccounts.selected.map(t => ({
        chain_id: this.id,
        account_id: t.ID
      }));

      this.$api
        .put(`chains/${this.id}/accounts/exclude`, data)
        .then(() => {
          self.fetchData(self.id);

          self.drawDataTable();

          this.chainAccounts.selected = [];
        })
        .catch(response => {
          console.log(response);

          self.$form.makeToastError(`Error: ${response.message}`);

          self.fetchData(self.id);

          self.drawDataTable();

          this.chainAccounts.selected = [];
        });
    },
    onAccountsTableLoad() {},
    onModalAccountSelected() {
      this.modalAccountsList.selected = this.$refs[
        "modal-accounts"
      ].getSelectedRows();
    },
    onChainAccountSelected() {
      this.chainAccounts.selected = this.$refs[
        "chain-accounts"
      ].getSelectedRows();
    }
  },
  watch: {}
};
</script>

<style>
table .multiselect {
  width: 300px;
}

table .multiselect__content-wrapper {
  position: relative !important;
}
</style>
