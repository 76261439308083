var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-card', [_c('div', {
    staticClass: "card-body"
  }, [_c('form', [_c('h5', [_vm._v(" Chain details "), _vm.isLoading ? _c('b-spinner', {
    staticClass: "mb-1",
    attrs: {
      "small": "",
      "type": "grow"
    }
  }) : _vm._e()], 1), _c('hr'), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "md": "2",
      "sm": "2"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.name.id,
      "value": _vm.data.name,
      "label": _vm.controls.name.label,
      "readonly": false,
      "mode": _vm.mode,
      "required": true
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "2",
      "sm": "2"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.domain.id,
      "value": _vm.data.domain,
      "label": _vm.controls.domain.label,
      "readonly": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "2",
      "sm": "2"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.keyword.id,
      "value": _vm.data.keyword,
      "label": _vm.controls.keyword.label,
      "readonly": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', [_c('inline-switch', {
    attrs: {
      "id": _vm.controls.approved.id,
      "value": _vm.data.approved,
      "readonly": _vm.controls.approved.readonly,
      "label": _vm.controls.approved.label
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12",
      "md": "2",
      "sm": "2"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.description.id,
      "value": _vm.data.description,
      "label": _vm.controls.description.label,
      "is-text-area": true,
      "readonly": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "2",
      "md": "2",
      "sm": "2"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.created.id,
      "value": _vm.data.created,
      "label": _vm.controls.created.label,
      "readonly": true,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "2",
      "md": "2",
      "sm": "2"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.modified.id,
      "value": _vm.data.modified,
      "label": _vm.controls.modified.label,
      "readonly": true,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1)], 1), _vm.id ? _c('div', [_c('hr'), _c('h5', [_vm._v(" Chain accounts ")]), _c('hr'), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('b-button-group', [_c('button', {
    staticClass: "btn btn-outline-dark btn",
    attrs: {
      "type": "button",
      "title": "Include accounts"
    },
    on: {
      "click": function click($event) {
        return _vm.showModalAccountsList();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "plus"
    }
  })], 1), _c('button', {
    staticClass: "btn btn-outline-dark btn",
    attrs: {
      "type": "button",
      "disabled": _vm.chainAccounts.selected.length === 0,
      "title": "Exclude accounts"
    },
    on: {
      "click": function click($event) {
        return _vm.excludeAccounts();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "minus"
    }
  })], 1)])], 1)], 1), _c('b-row', [_c('b-col', [_c('accounts-table', {
    ref: "chain-accounts",
    attrs: {
      "per-page": 5,
      "show-add-button": false,
      "selectable": true
    },
    on: {
      "loaded": _vm.onAccountsTableLoad,
      "row-select": _vm.onChainAccountSelected
    }
  })], 1)], 1)], 1) : _vm._e()], 1), _c('hr'), _c('form-submission-actions', {
    attrs: {
      "mode": _vm.mode,
      "loading": {
        save: _vm.saveInProgress,
        saveAndView: _vm.saveAndViewInProgress
      },
      "buttons-visibility": {
        previous: _vm.$customTable.getPrevKey(_vm.$route.meta.module, _vm.id),
        next: _vm.$customTable.getNextKey(_vm.$route.meta.module, _vm.id)
      }
    },
    on: {
      "previous-item": function previousItem($event) {
        _vm.$router.push({
          name: _vm.$route.name,
          params: {
            action: _vm.$route.params.action,
            id: _vm.$customTable.getPrevKey(_vm.$route.meta.module, _vm.id)
          }
        });
      },
      "next-item": function nextItem($event) {
        _vm.$router.push({
          name: _vm.$route.name,
          params: {
            action: _vm.$route.params.action,
            id: _vm.$customTable.getNextKey(_vm.$route.meta.module, _vm.id)
          }
        });
      },
      "save": function save($event) {
        _vm.save('tabular').then(function (response) {
          return response ? _vm.$router.push({
            name: 'Chains manager'
          }) : false;
        });
      },
      "save-and-view": function saveAndView($event) {
        _vm.save('view').then(function (response) {
          return response ? _vm.$router.push({
            name: 'Chain submission',
            params: {
              action: 'view',
              id: response
            }
          }) : false;
        });
      },
      "edit": function edit($event) {
        return _vm.$router.push({
          name: 'Chain submission',
          params: {
            action: 'edit',
            id: _vm.id
          }
        });
      },
      "back": function back($event) {
        return _vm.$router.push(_vm.$store.getters['router/previousRoute']);
      }
    }
  })], 1)]), _c('b-modal', {
    ref: "modal-accounts-list",
    attrs: {
      "centered": "",
      "hide-footer": "",
      "size": "xl",
      "title": "Select accounts"
    }
  }, [_c('accounts-table', {
    ref: "modal-accounts",
    attrs: {
      "autoload": true,
      "per-page": 5,
      "show-add-button": false,
      "show-actions": false,
      "selectable": true
    },
    on: {
      "loaded": _vm.onAccountsTableLoad,
      "row-select": _vm.onModalAccountSelected
    }
  }), _c('hr'), _c('div', {
    staticClass: "form-row d-flex justify-content-end"
  }, [_c('b-button', {
    staticClass: "m-1",
    attrs: {
      "variant": "outline-dark",
      "disabled": _vm.modalAccountsList.selected.length === 0
    },
    on: {
      "click": function click($event) {
        return _vm.includeAccounts();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "plus"
    }
  }), _vm._v(" Add ")], 1), _c('b-button', {
    staticClass: "m-1",
    attrs: {
      "variant": "outline-dark"
    },
    on: {
      "click": function click($event) {
        return _vm.closeModalAccountsList();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "times"
    }
  }), _vm._v(" Close ")], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }